import React from 'react'
import 'twin.macro'
// import  FactDetail from './FactDetail'
// import  HeroDetail from './HeroDetail'
// import  InfoDetail from './InfoDetail'
// import  ServicesDetail from './ServicesDetail'

export default function Detail() {
    return (
        <>
        {/* <HeroDetail />
        <FactDetail />
        <InfoDetail />
        <ServicesDetail /> */}
        </>
    )
}
